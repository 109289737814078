<template>
  <v-container pa-5 fluid>
    <formulario-Ingresso v-model="ingressoForm" :ingresso="true" />
  </v-container>
</template>

<script>
import formularioIngresso from "./componentes/formularioIngresso.vue";

export default {
  edit: {
    type: Boolean,
    default: false,
  },
  components: {
    formularioIngresso,
  },
  data() {
    return {
      hasIngresso: true,
    };
  },
};
</script>

<style lang="scss">
.border {
  border: 2px dashed gray;
}
</style>
